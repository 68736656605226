import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface RedirectProps {
  to: string | {
    pathname: string;
    search?: string;
    state?: any;
  };
}

export const Redirect: React.FC<RedirectProps> = ({ to }) => {
  const history = useHistory();

  useEffect(() => {
    if (typeof to === 'string') {
      history.replace(to);
    } else {
      history.replace({
        pathname: to.pathname,
        search: to.search,
        state: to.state
      });
    }
  }, [history, to]);

  // Return null since we're handling navigation via history
  return null;
};
