import React, { useEffect } from 'react';
import './IntroSlide.scss';
import { ScrollElementWrapper } from '../common/ScrollElementWrapper';
import userActions from '../../actions/userActions';
import { useTranslation } from 'react-i18next';

interface IntroSlideProps {
  start: any;
  userId: string;
}

const IntroSlide = (props: IntroSlideProps) => {
  const { start, userId } = props;
  const userLanguage = global.language as any;
  const [name, setName] = React.useState('');
  const [year, setYear] = React.useState('');
  const [month, setMonth] = React.useState('');
  const { t } = useTranslation();

  const loadData = async () => {
    const today = new Date();
    const date = new Date(start);
    const {
      profile: { name },
    } = await userActions.getUser(userId);

    setName(name);
    setMonth(date.toLocaleDateString(userLanguage, { month: 'long' }));

    if (
      date.toLocaleDateString(userLanguage, { year: 'numeric' }) !==
      today.toLocaleDateString(userLanguage, { year: 'numeric' })
    ) {
      setYear(date.toLocaleDateString(userLanguage, { year: 'numeric' }));
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <ScrollElementWrapper name="intro">
      <section className="intro-slide">
        <div className="flex-wrapper vertical-center">
          <div className="flex-container">
            <p>
              {t('IntroSlide.h1.text')}
              <span className="username">
                &nbsp;
                {name}
              </span>
              !
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: t('IntroSlide.h2.text'),
                }}
              />
              <span className="highlight">
                {`${month} `}
                {year}.
              </span>
              <br />
            </p>
          </div>
        </div>
      </section>
    </ScrollElementWrapper>
  );
};

export default IntroSlide;
