import React from 'react';
import { scroller } from 'react-scroll';

interface ScrollElementProps {
  name: string;
  children?: React.ReactNode;
  className?: string;
}

export const ScrollElementWrapper: React.FC<ScrollElementProps> = ({ name, children, className }) => {
  const elementRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (elementRef.current) {
      scroller.register(name, elementRef.current);
      return () => scroller.unregister(name);
    }
  }, [name]);

  return (
    <div ref={elementRef} className={className}>
      {children}
    </div>
  );
};
