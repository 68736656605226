import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import subscriptionActions from '../../actions/subscriptionActions';
import { Redirect } from '../common/RedirectWrapper';

interface Props {
  userId: string;
  pauseDuration: number;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PauseSuccess = (props: Props) => {
  const { userId } = props;
  const { t } = useTranslation();
  let query = useQuery();
  const duration = query.get('duration');
  const [toSubscription, setToSubscription] = useState<boolean>(false);

  const continueHandler = () => {
    setToSubscription(true);
  };

  const unpauseHandler = async () => {
    try {
      await subscriptionActions.unPauseSubscription(userId);
      setToSubscription(true);
    } catch (error) {
      // TODO: handle if something goes wrong
    }
  };

  if (toSubscription) {
    return <Redirect to={`/${global.language}/subscription`} />;
  }

  return (
    <div className="profile" style={{ alignItems: 'center', maxWidth: 480 }}>
      <div className="details">
        <div>
          <h2 style={{ textAlign: 'center' }}>
            {t('app_manageSubscription:MembershipPaused.title', { pausedDuration: duration })}
          </h2>
        </div>

        <div>
          <p style={{ textAlign: 'center' }}>{t('app_manageSubscription:MembershipPaused.subtitle')}</p>
        </div>

        <button id="sendOrderBt" className="button lined" type="submit" onClick={unpauseHandler}>
          {t('app_manageSubscription:MembershipPaused.unpause.button')}
        </button>

        <button id="sendOrderBt" className="button lined" type="submit" onClick={continueHandler}>
          {t('app_manageSubscription:MembershipPaused.continue.button')}
        </button>
      </div>
    </div>
  );
};

PauseSuccess.defaultProps = {
  pauseDuration: 0,
};

export default PauseSuccess;
