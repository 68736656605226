import React, { useEffect, useState } from 'react';
import IonIcon from '@reacticons/ionicons';
import { useTranslation } from 'react-i18next';
import addressActions from '../../actions/addressActions';
import { FormFieldValidation } from './NewAddressForm';
import { RouteComponentProps } from 'react-router-dom';
import { Redirect } from '../common/RedirectWrapper';
import countriesActions from '../../actions/countriesActions';
import isoCountries, { AllowedCountries } from '../../utils/countryList';

const defaultFormValidationState = {
  fullname: '',
  address: '',
  zipcode: '',
  city: '',
  countryCode: '',
  fullnameValid: false,
  addressValid: false,
  zipcodeValid: false,
  cityValid: false,
};
interface IObjectKeys {
  [key: string]: string | number;
}
const AddressBookEditItem = (props: any) => {
  const { addressId, userId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [countries, setCountries] = useState<IObjectKeys>({});

  const loadTranslatedCountries = async () => {
    let allowedCountries: AllowedCountries[];
    try {
      const activeCountries = await countriesActions.getCountries();
      allowedCountries = activeCountries
        .map((country: any) => country.countryCode)
        .filter((countryCode: string) => countryCode !== 'EU');
    } catch (error) {
      allowedCountries = ['NL', 'BE', 'FR', 'GB', 'DE', 'ES'];
    }
    const countries: IObjectKeys = {};
    Object.values(allowedCountries).forEach((countryCode) => {
      const countryName = isoCountries[countryCode];
      countries[countryCode] = countryName;
    });
    setCountries(countries);
  };

  useEffect(() => {
    loadTranslatedCountries();
  }, []);

  const [address, setAddress] = useState({
    fullname: '',
    address: '',
    address2: '',
    region: '',
    zipcode: '',
    city: '',
    countryCode: '',
  });
  const [fullNameIsValid, setFullNameIsValid] = useState<boolean>(true);
  const [addressIsValid, setAddressIsValid] = useState<boolean>(true);
  const [zipCodeIsValid, setZipCodeIsValid] = useState<boolean>(true);
  const [cityIsValid, setCityIsValid] = useState<boolean>(true);
  const [formIsValid, setFormIsValid] = useState<boolean>(true);
  const [formValidation, setFormValidation] = useState<FormFieldValidation>(defaultFormValidationState);

  const { t } = useTranslation();

  useEffect(() => {
    if (!userId || !addressId) return;
    addressActions
      .getAddress(userId, addressId)
      .then((res) => {
        setAddress(res);
      })
      .catch((e) => console.log);
  }, [userId, addressId]);

  useEffect(() => {
    setFormIsValid(fullNameIsValid && addressIsValid && cityIsValid && zipCodeIsValid);
  }, [fullNameIsValid, addressIsValid, cityIsValid, zipCodeIsValid]);

  if (!address) return <div>Loading</div>;

  const saveAddressAndContinue = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await addressActions.editAddress(userId, addressId, address);

      setRedirect(true);
      alert(t('AddressBook.update.success'));
    } catch (error) {
      console.error('Error updating address:', error);
      alert(t('AddressBook.update.fail'));
    }
    setIsLoading(false);
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/${global.language}/addressbook`,
        }}
      />
    );
  }

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setAddress((prevAddress: any) => ({
      ...prevAddress,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (name: string, value: string) => {
    let formErrors: FormFieldValidation = defaultFormValidationState;
    switch (name) {
      case 'fullname':
        const fullnameValid = value.length > 0;
        setFullNameIsValid(value.length > 0);
        formErrors.fullname = fullnameValid ? undefined : t('SendToNewAddress.validation.message');
        break;
      case 'address':
        const addressValid = value.length > 0;
        setAddressIsValid(value.length > 0);
        formErrors.address = addressValid ? undefined : t('SendToNewAddress.validation.message');
        break;
      case 'zipcode':
        const zipcodeValid = value.length > 3;
        setZipCodeIsValid(value.length > 3);
        formErrors.zipcode = zipcodeValid ? undefined : t('SendToNewAddress.validation.message');
        break;
      case 'city':
        const cityValid = value.length > 0;
        setCityIsValid(value.length > 0);
        formErrors.city = cityValid ? undefined : t('SendToNewAddress.validation.message');
        break;
      default:
        break;
    }

    setFormValidation(formErrors);
  };

  return (
    <span>
      <div className="details">
        <div className="addressBook">
          <div className="form">
            <form onSubmit={saveAddressAndContinue}>
              <>
                <fieldset>
                  <label htmlFor="address-name">{t('SendToNewAddress.name.text')} *</label>
                  <div className="input-wrapper">
                    <input
                      name="fullname"
                      id="address-name"
                      value={address.fullname}
                      onChange={handleInputChange}
                      className="name"
                      type="text"
                      required
                    />
                    <IonIcon name="checkmark" className="done" />
                  </div>
                  <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
                </fieldset>
                <fieldset>
                  <label htmlFor="address-address">{t('SendToNewAddress.streetAndHouseNumber.text')} *</label>
                  <legend>{t('SendToNewAddress.streetAndHouseNumber.example')}</legend>
                  <div className="input-wrapper">
                    <input
                      name="address"
                      id="address-address"
                      value={address.address}
                      onChange={handleInputChange}
                      className="address"
                      type="text"
                      maxLength={300}
                      required
                    />
                    <IonIcon name="checkmark" className="done" />
                  </div>
                  <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
                </fieldset>
                <fieldset>
                  <label htmlFor="address-address">{t('SendToNewAddress.address2.text')}</label>
                  <div className="input-wrapper">
                    <input
                      name="address2"
                      id="address-address2"
                      value={address.address2}
                      onChange={handleInputChange}
                      className="address"
                      type="text"
                      maxLength={300}
                    />
                    <IonIcon name="checkmark" className="done" />
                  </div>
                  <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
                </fieldset>
                <fieldset>
                  <label htmlFor="address-zipcode">{t('SendToNewAddress.postcode.text')} *</label>
                  <legend>{t('SendToNewAddress.postcode.example')}</legend>
                  <div className="input-wrapper">
                    <input
                      name="zipcode"
                      id="address-zipcode"
                      value={address.zipcode}
                      onChange={handleInputChange}
                      className="zipcode"
                      type="text"
                      required
                    />
                    <IonIcon name="checkmark" className="done" />
                  </div>
                  <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
                </fieldset>
                <fieldset>
                  <label htmlFor="address-address">{t('SendToNewAddress.region.text')}</label>
                  <legend>{t('SendToNewAddress.region.example')}</legend>
                  <div className="input-wrapper">
                    <input
                      name="region"
                      id="address-region"
                      value={address.region}
                      onChange={handleInputChange}
                      className="address"
                      type="text"
                      maxLength={300}
                    />
                    <IonIcon name="checkmark" className="done" />
                  </div>
                  <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
                </fieldset>
                <fieldset>
                  <label htmlFor="address-city">{t('SendToNewAddress.city.text')} *</label>
                  <legend>{t('SendToNewAddress.city.example')}</legend>
                  <div className="input-wrapper">
                    <input
                      id="address-city"
                      className="city"
                      value={address.city}
                      onChange={handleInputChange}
                      name="city"
                      type="text"
                      maxLength={200}
                      required
                    />
                    <IonIcon name="checkmark" className="done" />
                  </div>
                  <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
                </fieldset>
                <fieldset>
                  <label htmlFor="country">{t('SendToNewAddress.country.text')}</label>
                  <select id="country-code" name="countryCode" onChange={handleInputChange} value={address.countryCode}>
                    {countries &&
                      Object.entries(countries).map(([countryCode, countryName]) => (
                        <option key={countryCode} value={countryCode}>
                          {countryName}
                        </option>
                      ))}
                  </select>
                </fieldset>
              </>

              <fieldset>
                <button id="sendOrderBt" className="button" type="submit" disabled={!formIsValid}>
                  {t('AddressBook.NewAddressForm.save.button')}
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </span>
  );
};
export default AddressBookEditItem;
