import React, { FC } from 'react';
import PropTypes from 'prop-types';

import { Link, LinkProps } from 'react-router-dom';

interface TranslatedLinkProps extends Omit<LinkProps, 'to'> {
  to: string;
  className?: string;
  onClick?: () => void;
}

// Regular link just that it has global.language added to the path
const TranslatedLink: FC<TranslatedLinkProps> = (props) => {
  const { to, ...otherProps } = props;

  let trimmedToLink = to;

  if (trimmedToLink.startsWith('/')) {
    trimmedToLink = trimmedToLink.substr(1);
  }

  const translatedTo = `/${global.language}/${trimmedToLink}`;

  return <Link to={translatedTo} {...otherProps} />;
};

TranslatedLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default TranslatedLink;
