import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import subscriptionActions from '../../actions/subscriptionActions';
import { Redirect } from '../common/RedirectWrapper';

const CancelSuccess = (props: any) => {
  const { userId } = props;
  const { t } = useTranslation();
  const [toSubscription, setToSubscription] = useState<boolean>(false);

  const continueHandler = () => {
    setToSubscription(true);
  };

  const reactivateMembershipHandler = async () => {
    try {
      await subscriptionActions.unPauseSubscription(userId);
      setToSubscription(true);
    } catch (error) {
      // TODO: handle if something goes wrong
    }
  };

  if (toSubscription) {
    return <Redirect to={`/${global.language}/subscription`} />;
  }

  return (
    <div className="profile" style={{ alignItems: 'center', maxWidth: 480 }}>
      <div className="details">
        <div>
          <h2>{t('app_manageSubscription:MembershipCancelled.screen.title')}</h2>
        </div>

        <div>
          <p style={{ textAlign: 'center' }}>{t('app_manageSubscription:MembershipCancelled.subtitle')}</p>
        </div>

        <div style={{ display: 'grid' }}>
          <button id="sendOrderBt" className="button lined" type="submit" onClick={reactivateMembershipHandler}>
            {t('app_manageSubscription:MembershipCancelled.reactivate.button')}
          </button>

          <button id="sendOrderBt" className="button lined" type="submit" onClick={continueHandler}>
            {t('app_manageSubscription:MembershipCancelled.continue.button')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelSuccess;
