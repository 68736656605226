import './CancelOffers.scss';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PauseMembership from '../PauseMembership/PauseMembership';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { Redirect } from '../common/RedirectWrapper'; 
import { CancelReason } from './CancelSubscription';
import CancelReasonResolution from './CancelReasonResolution';
import subscriptionActions from '../../actions/subscriptionActions';
import { ISubscription, ISubscriptionPauseComplete } from '@klikkie/klikkie-schemas/lib/interfaces/Subscription';
import { database } from '../../services/firebase';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CancelOffers = (props: any) => {
  const { userId, subscription } = props;
  const { t } = useTranslation();
  const query = useQuery();
  const [showPausedState, setShowPausedState] = useState<boolean>(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [showCancelledState, setShowCancelledState] = useState<boolean>(false);
  const [showCancelConfirmDialog, setShowCancelConfirmDialog] = useState<boolean>(false);
  const [pauseDuration, setPauseDuration] = useState<number | undefined>(undefined);
  const [subscriptionState, setSubscription] = useState<ISubscription | undefined>(undefined);
  console.log({ userId, props });
  const reason = useMemo(() => {
    let reasonValue: CancelReason | undefined;

    if (query.get('reason')) {
      reasonValue = query.get('reason') as CancelReason;
    }

    return reasonValue;
  }, [query.get('reason')]);

  const handleSubscriptionChangeResponse = async (snapshot: any | null) => {
    let updatedSubscription = snapshot.val();

    updatedSubscription = {
      ...updatedSubscription,
      userId,
    };
    setSubscription(updatedSubscription);
  };

  useEffect(() => {
    const onValueChange = database.ref(`Subscriptions/${userId}`).on('value', (snapshot) => {
      handleSubscriptionChangeResponse(snapshot);
    });
    console.log(onValueChange);

    // Stop listening for updates when no longer required
    return () => database.ref(`Subscriptions/${userId}`).off('value', onValueChange);
  }, []);

  useEffect(() => {
    if (subscriptionState && subscriptionState.status == 'paused') {
      const { status } = subscriptionState;
      if (isCancelling && status == 'paused') {
        setShowCancelledState(true);
      }
    }
  }, [subscriptionState, isCancelling]);

  const pauseSuccessHandler = (pauseDurationValue: number) => {
    setPauseDuration(pauseDurationValue);
    setShowPausedState(true);
  };

  const handleDialogClose = () => {
    setShowCancelConfirmDialog(false);
  };

  const cancelHandler = async () => {
    try {
      setIsCancelling(true);
      const pauseBody: ISubscriptionPauseComplete = {
        comment: '',
        reason: reason || '',
      };
      console.log('cancelHandler... 1');
      await subscriptionActions.pauseSubscription(userId, pauseBody);
      console.log('cancelHandler... 2');
      setShowCancelledState(true);
    } catch (error) {
      setIsCancelling(false);
      console.log('cancelHandler... 3');
      // TODO: handle if something goes wrong
    }
  };

  if (showPausedState) {
    return (
      <Redirect
        to={{
          pathname: `/${global.language}/membership-paused`,
          search: `?duration=${pauseDuration}`,
          state: { pauseDuration }
        }}
      />
    );
  }

  if (showCancelledState) {
    return <Redirect to={`/${global.language}/membership-cancelled`} />;
  }

  const cancelButtonHandler = () => {
    setShowCancelConfirmDialog(true);
  };

  return (
    <div className="profile" style={{ alignItems: 'center' }}>
      <div className="details">
        <CancelReasonResolution reason={reason} />

        <PauseMembership pauseSuccessHandler={pauseSuccessHandler} userId={userId} reason={reason} />
        <button id="sendOrderBt" className="button lined" type="submit" onClick={cancelButtonHandler}>
          {t('app_manageSubscription:CancelOffers.finishCancellation.button')}
        </button>
      </div>
      <Dialog
        open={showCancelConfirmDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('app_alerts:Subscription.Cancel.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('app_alerts:Subscription.Cancel.message')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('app_alerts:Subscription.Cancel.cancel.button')}
          </Button>
          <Button onClick={cancelHandler} style={{ color: 'red' }}>
            {t('app_alerts:Subscription.Cancel.confirm.button')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CancelOffers;
